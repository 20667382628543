//コピーライト
var TodayData = new Date();
var thisYear = TodayData.getFullYear();
window.onload = function() {
	document.getElementById('js_copyYear').innerHTML = '©' + ' ' + thisYear + ' 株式会社 花セレモニー';
};


// ヘッダー動作（下にスクロールで隠れて、上にスクロールで再表示する）
var startPos = 0;
$(window).scroll(function(){
	var currentPos = $(this).scrollTop();
	var w = $(window).width();
	if (w > 768) {
		if (currentPos > startPos) {
			if($(window).scrollTop() >= 100) {
				$("#header").css("top", "-" + 124 + "px");
			}
		} else {
				$("#header").css("top", 0 + "px");
				$("#header").css("transition", .3 + "s");
		}
		startPos = currentPos;
	}
});


// 【PC版 トップページ】スクロールするまで専用グロナビ（途中から.js_topMenuPcを削除）
$(function(){
	$(window).scroll(function(){
		var scr = $(window).scrollTop();
		if(scr >= 140){
			$("#header").addClass('js_miniHeaderPc');
		}else{
			$("#header").removeClass('js_miniHeaderPc');
		}
	})
})


// ハンバーガーメニュー
$(function(){
	$('#js_GNav__menuBtn').on("click", function(){
		$(this).toggleClass('-open');
	});
});


// フッターの固定ボタン
$(document).ready(function(){

		// ページがスクロールされるまでは隠しておく
		$("#FtFixed").hide();

		// スクロールが始まったら
		$(window).on("scroll", function() {
				// 100pxスクロールしたら表示
				if ($(this).scrollTop() > 100) {
						$('#FtFixed').slideDown("fast");
				// ページの一番上では非表示
				} else {
						$('#FtFixed').slideUp("fast");
				}

				// ページの高さ
				var scrollHeight = $(document).height();

				//  表示画面の高さ ＋ ページに対するスクロール量
				var scrollPosition = $(window).height() + $(window).scrollTop();

				//  フッターの高さ
				var footHeight = $("#footer").innerHeight();

				// フッターの位置（ページの長さ − 表示画面の高さ ＋ ページに対する現在のスクロール量）までスクロールしてきたとき
				if ( scrollHeight - scrollPosition  <= footHeight ) {

				// 現在の下から位置が、フッターの高さの位置にはいったら
				// ".gotop"のpositionをabsoluteに変更し、フッターの高さの位置にする
						$("#FtFixed").css({
								"position": "absolute",
								"bottom": footHeight,
						});
				} else {
				// それ以外の場合は元のcssスタイルを指定
						$("#FtFixed").css({
								"position": "fixed",
								"bottom": "-1px",
						});
				}
		});
});


// タブ
$(function() {
		$(".js_tab .tabTitle").click(function() {
				var num = $(".js_tab .tabTitle").index(this);
				$(".tabContent").removeClass('active');
				$(".tabContent").eq(num).addClass('active');
				$(".js_tab .tabTitle").removeClass('active');
				$(this).addClass('active')
		});
});


// スムーズスクロール
$(function(){
	 $('a[href^="#"]').click(function() {
			var speed = 300;
			var href= $(this).attr("href");
			var target = $(href == "#" || href == "" ? 'html' : href);
			var position = target.offset().top;
			$('html,body').animate({scrollTop:position}, speed, 'swing');
			return false;
	 });
});


// ホバーメニュー
$(function(){
	$('.pullDownPc').hover(function() {
		var w = $(window).width();
		if (w > 768) {
			$(this).toggleClass('active');
			$(this).children('.pullDownContentPc').stop().slideToggle();
		}
	});
});


// トグル
$(function(){
	$('.js_toggleBtn').click(function() {
			$(this).toggleClass('-active');
			$(this).next('.js_togglContents').slideToggle();
	});
});


// lightBox
// $(function () {
// 	$(".lb").lightbox();
// });
